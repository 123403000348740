<template>
  <div class="container mx-auto py-4 px-4 sm:px-0 containerWhite">
    <div class="flex creative-bar">
      <input
        v-model="prefix"
        class="search-bar1"
        :placeholder="$t('dashboard.searchWebsite')"
        icon-name="find"
      />
      <b-icon class="icon-search1" name="search" />
    </div>
    <div class="flex mb-2 containerFixedRight">
      <div class="flex containerFixedLeft">
        <div class="menuContainerWebsites textTitleCounts">
          <div class="textMenuContainerWebsites">
            {{ $t("dashboard.Websites") }}
          </div>
          <div class="flexjust">
            <div class="flexChildren">
              <button
                class="btnContainerWebsites textSubtitleCounts"
                @click="filterWebsitesStatus()"
              >
                {{ $t("dashboard.all") }}
              </button>
              <p class="bgContainerWebsitesNumbersAll">{{ totalSites }}</p>
            </div>

            <div class="flexChildren">
              <button
                class="btnContainerWebsites textSubtitleCounts"
                @click="filterWebsitesStatus(false)"
              >
                {{ $t("dashboard.free") }}
              </button>
              <p class="bgContainerWebsitesNumbersFree">{{ totalFreeSites }}</p>
            </div>
            <div class="flexChildren lastChildren">
              <button
                class="btnContainerWebsites textSubtitleCounts"
                @click="filterWebsitesStatus(true)"
              >
                {{ $t("dashboard.pro") }}
              </button>
              <p class="bgContainerWebsitesNumbersPro">{{ totalProSites }}</p>
            </div>
            <!-- <div class="flexChildren">
              <button class="btnContainerWebsites textSubtitleCounts">
                Licencias Pro Disponibles
              </button>
              <p class="bgContainerWebsitesNumbersPro">
                {{ numeroProDisponible }}
              </p>
            </div> -->
          </div>
        </div>
      </div>
      <div
        class="
        grid
        gap-4
        grid-cols-1
        sm:grid-cols-2
        md:grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-3
        p-8
      "
      >
        <dashboard-card
          class="flex items-center justify-center cursor-pointer newCard"
          @click="requestSheetsAccess"
        >
          <div>
            <b-icon class="ml-2 iconSmall" name="plus" />
            <span class="is-block textNewCard">
              {{ $t("builder.new_website") }}
            </span>
            <span v-if="!hasSheetsAccess" class="text-xs">
              {{ $t("builder.google_sheets_access_needed") }}
            </span>
          </div>
        </dashboard-card>
        <dashboard-card
          v-if="rootUser"
          class="flex items-center justify-center cursor-pointer"
          @click="admin"
        >
          <div>
            <b-icon name="edit" />
            <span class="is-block has-text-weight-bold"> Administrar </span>
          </div>
        </dashboard-card>
        <website-card
          v-for="website in filterWebsites"
          :key="`website-${website._id}`"
          :websites="websites"
          :website="website"
          :user="user"
          :find-my-websites="findMyWebsites"
          @upgrade="showPaymentModal"
        >
        </website-card>
      </div>
    </div>
    <payment-modal
      :activate-modal="isPaymentModalActive"
      :website-id="selectedWebsite._id || ''"
      :website-url="selectedWebsite.domain || ''"
      @close="isPaymentModalActive = false"
    />
    <b-loading
      :is-full-page="true"
      :active.sync="isCreatingWebsite"
      :can-cancel="false"
    ></b-loading>
    <QrModal
      v-show="isQrModalActive"
      :website-url="selectedWebsite.domain || ''"
    ></QrModal>
  </div>
</template>

<script>
import ApiSites from "@/services/sites";
import BIcon from "@/components/BIcon";
import PaymentModal from "@/components/PaymentModal/PaymentModal";
import auth from "@/plugins/firebase/auth";
import db from "@/plugins/firebase/db";
import router from "@/router";
import { mapState, mapGetters } from "vuex";
import * as Sentry from "@sentry/browser";
import QRCode from "qrcode";
import DashboardCard from "./DashboardCard";
import WebsiteCard from "./WebsiteCard";
import QrModal from "../../components/QrModal.vue";
export default {
  name: "Dashboard",

  components: {
    BIcon,
    DashboardCard,
    PaymentModal,
    WebsiteCard,
    QrModal
  },

  data() {
    return {
      showMenuId: null,
      websitesMongo: [],
      websitesFirebase: [],
      dbWebsites: [],
      websites2: [],
      isCreatingWebsite: false,
      isPaymentModalActive: false,
      isQrModalActive: false,
      selectedWebsite: "",
      rootUser: false,
      prefix: "",
      filterType: "",
      numeroProDisponible: 0
    };
  },

  computed: {
    ...mapState(["hasSheetsAccess", "authorizeUrl", "user", "websites"]),
    ...mapGetters([
      "totalSites",
      "totalFreeSites",
      "totalProSites",
      "proWebsites",
      "freeWebsites"
    ]),
    isPaymentsEnabled() {
      return process.env.VUE_APP_ENABLE_PAYMENTS === "true";
    },
    isAgency() {
      return this.$store.getters["isAgency"];
    },
    filterWebsites() {
      const search = this.prefix.trim().toLowerCase();
      return this.filteredWebsites.filter(
        ({ name }) => search === "" || name.toLowerCase().includes(search)
      );
    },
    filteredWebsites() {
      if (this.filterType === "pro") return this.proWebsites;
      if (this.filterType === "free") return this.freeWebsites;
      return this.websites.slice();
    }
  },
  created() {
    this.findMyWebsites();
    this.getUser();
  },
  async mounted() {
    let intervalId = setInterval(() => {
      const existsDownWebsites = this.websites.some(
        website => website?.status !== "up"
      );
      if (existsDownWebsites) {
        this.verifyStatusSite();
      } else {
        clearInterval(intervalId);
      }
    }, 30000);
  },

  methods: {
    checkConnection(url = "") {
      return new Promise(resolve => {
        const img = new Image();
        img.src = url + "/favicon.ico" + "?t=" + Date.now(); // Se agrega un parámetro único para evitar la caché
        img.onload = function() {
          resolve(true); // Conectividad exitosa
        };
        img.onerror = function() {
          resolve(false); // Error de conectividad
        };
      });
    },
    async verifyStatusSite() {
      const self = this;
      const filterdWebsites = this.websites.filter(
        website => website?.status !== "up"
      );
      filterdWebsites.forEach(website => {
        const siteUrl = `https://${website.domain}`;
        this.checkConnection(siteUrl).then(isAvailable => {
          if (isAvailable) {
            ApiSites.uptadeStatusWebsite(website.websiteId).then(({ data }) => {
              self.$store.commit("SET_REPLACE_WEBSITE", data);
            });
          }
        });
      });
    },
    async findMyWebsites() {
      this.numeroProDisponible = 0;
      let res = null;
      res = await ApiSites.findMyWebsites({
        uid: this.userId
      });
      this.$store.dispatch("setWebsites", res.data || []);
    },
    filterWebsitesStatus(WebPros) {
      if (WebPros === undefined) {
        this.filterType = "";
        return;
      }
      this.filterType = WebPros ? "pro" : "free";
    },
    async getUser() {
      try {
        this.$store.dispatch("getUser", {
          uid: this.user.uid,
          email: this.user.email
        });
        db.collection("users")
          .doc(auth.currentUser.uid)
          .onSnapshot(doc => {
            const user = doc.data();
            this.rootUser = user.rootUser;
            if (!this.hasSheetsAccess && user?.googleApisTokens) {
              this.$buefy.notification.open({
                message: "Google sheets access granted",
                type: "is-success"
              });
              this.$store.commit("SET_SHEET_ACCESS", true);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    async requestSheetsAccess() {
      try {
        if (!this.hasSheetsAccess) {
          window.open(this.authorizeUrl);
        } else {
          const websitesLimit = this.user.websitesLimit;
          if (!this.isAgency && this.freeWebsites.length >= websitesLimit) {
            this.$buefy.notification.open({
              message:
                "You've reached your free website limit,try deleting one free or upgrade to pro",
              type: "is-warning"
            });
          } else {
            this.isCreatingWebsite = true;
            this.$store
              .dispatch("builder/createWebsite", {
                options: { demo: this.isAgency }
              })
              .finally(() => {
                this.isCreatingWebsite = true;
              });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async generateQR() {
      try {
        const qrCode = await QRCode.toDataURL(this.websiteUrl, {
          margin: 2,
          width: 150
        });
        this.qrCode = qrCode;
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    showPaymentModal(website) {
      this.isPaymentModalActive = true;
      this.selectedWebsite = website;
    },

    showQrModal(website) {
      this.isQrModalActive = true;
      this.selectedWebsite = website;
    },

    toggleOptionsMenu(websiteId) {
      if (this.showMenuId === websiteId) {
        this.showMenuId = null;
      } else {
        this.showMenuId = websiteId;
      }
    },
    admin() {
      this.$store.commit("SET_IS_ADMIN", true);
      router.push({ name: "builder" });
    }
  }
};
</script>

<style lang="scss" scoped>
.containerWhite {
  background-color: whitesmoke !important;
}

.textMenuContainerWebsites {
  margin-left: 10%;
  margin-right: 5%;
}

.menuContainerWebsites {
  border-color: #d0d0d0;
  border-width: 1.5px;
  height: 30px;
  border-bottom-width: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #fefefe;
  width: 175px;
}

.containerFixedLeft {
  position: fixed;
  top: 130px;
  left: 10px;
}

.containerFixedRight {
  margin-left: 13%;
}

.contenedorContainerCounts {
  position: fixed;
  z-index: 5;
  width: 200px;
  border-radius: 5px;
  border-color: #d0d0d0;
  border-width: 0.5px;
}

.textTitleCounts {
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.25px;
}

.textSubtitleCounts {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.flexjust {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-color: #d0d0d0;
  border-width: 1.5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.containFlex {
  align-content: center;
  margin-left: 0px;
  margin-right: 10%;
}

.flexChildren {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-color: #d0d0d0;
  background-color: #fefefe;
  border-left-width: 1px;
  border-right-width: 1px;
  width: 175px;
  height: 30px;
}

.lastChildren {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.flexChildren:hover {
  background-color: #eef4ff;

  .textSubtitleCounts {
    font-family: Roboto;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.25px;
  }
}

.btnContainerWebsites {
  width: 150px;
}

.bgContainerWebsitesNumbersAll {
  background-color: #c9deff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  align-items: center;
  align-content: center;
  margin-right: 10%;
  text-align: center;
}

.bgContainerWebsitesNumbersFree {
  background-color: #bdc0c3;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  align-items: center;
  align-content: center;
  margin-right: 10%;
  text-align: center;
}

.bgContainerWebsitesNumbersPro {
  color: white;
  background-color: #08587a;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  align-items: center;
  align-content: center;
  margin-right: 10%;
  text-align: center;
}

.recoilDiv {
  margin: 2%;
  margin-top: 2%;
}

.apps-wrapper {
  margin-top: 3rem;
  background-color: $white-ter;
}

.search-bar1 {
  width: 31%;
  border-radius: 5px;
  border-color: #d0d0d0;
  border-width: 0.5px;
}

.creative-bar {
  justify-content: center;
  width: 100%;
  z-index: 2;
}

.icon-search1 {
  position: relative;
  left: -30.5%;
  height: auto;
}

.textNewCard {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.25px;
}

.iconSmall {
  width: 21px;
  height: 21px;
}

.newCard {
  border-width: 2px;
  border-color: #93bdff;
  border-style: dashed;
  background-color: #fff;
  min-width: 320px;
}

.newCard:hover {
  border-width: 2px;
  border-color: #93bdff;
  border-style: dashed;

  .textNewCard {
    font-family: Roboto;
    font-weight: 500;
    font-size: 22.19px;
    letter-spacing: 0.28px;
    background-color: #f0f6ff;

    .iconSmall {
      width: 24px;
      height: 24px;
    }
  }
}

input {
  padding-left: 30px;
  /* Ajusta el padding izquierdo para dar espacio al icono */
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: padding-left 0.2s ease;
  /* Agrega una transición suave al ajuste del padding */
}

input:focus {
  padding-left: 25px;
  /* Ajusta el padding cuando el input está enfocado */
}
</style>
